<script>
import { api } from "@/state/services";

export default {
  props: {
    id: {
      default: ""
    },
    quantity: {
      default: ""
    }
  },
  components: { },
  data() {
    return {
      loading: false,
      qty: this.$props.quantity,
      cart: {
        items: {
          total: 0,
          list: null
        },
        subtotal: 'R$ 0,00',
        shipping: {
          method: '-',
          value: '-',
        },
        total: 'R$ 0,00'
      }
    };
  },
  methods: {
    buyProduct() {
      if (this.qty != this.$props.quantity && this.qty > 0) {
        this.loading = true

        api
          .post('store/cart', {
            id: this.$props.id,
            qty: this.qty
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$noty.success('A quantidade foi atualizada com sucesso.')

              this.cart.items.total = response.data.items.total
              this.cart.items.list = response.data.items.list
              this.cart.subtotal = response.data.subtotal
              this.cart.shipping = response.data.shipping
              this.cart.total = response.data.total

              localStorage.cart = JSON.stringify(this.cart)

              this.$parent.$parent.getCart()
            } else {
              this.qty = this.$props.quantity
              this.$noty.error(response.data.message)
            }

            this.loading = false
          })
          .catch(error => {
            if (error) {
              this.$noty.error('Não foi possível adicionar o produto em seu carrinho, tente novamente.')
            }
          })
      }
    }
  },
  mounted() {
  }
};
</script>

<template>
  <b-form @submit.prevent="buyProduct()" style="width:100px;" class="m-auto">
    <b-form-group class="m-0">
      <b-input-group>
        <b-form-input class="text-center" v-model="qty" type="text" v-mask="'###'" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button v-if="loading" style="width:40px;" class="btn-block font-size-20 py-0 px-2 text-center" type="submit" variant="secondary"><b-spinner small class="align-middle" variant="white" role="status"></b-spinner></b-button>
          <b-button v-else style="width:40px;" class="btn-block font-size-20 py-0 px-2 text-center" type="submit" variant="secondary"><i class="bx bx-analyse align-middle"></i></b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-form>
</template>